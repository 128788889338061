<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Swal from "sweetalert2";
import Table from "@/views/pages/admin/shift/team-members/components/table";

export default {
    components: {
        Layout,PageHeader,
        Table,
    },

    data(){
        return{
        items: [
                    {
                        text: "Shift Record",
                    },
                    {
                        text: "Shift",
                        active: true,
                    },
                ],
            currentTabComponent: 'Pending',
            tabs: [
                    {
                        id:1,
                        desc: 'Requesting for Pickup',
                        status: 'Pending',
                    },
                    {
                        id:2,
                        desc: 'Approved Shifts',
                        status: 'Accepted',
                    },
                        {
                            id:3,
                            desc: 'Requesting for Dropped Shifts',
                            status: 'ApplyForLeave',

                        },
                    {
                        id:4,
                        desc: 'Dropped Shifts',
                        status: 'Released',
                    },
                    {
                        id:5,
                        desc: 'Rejected Shifts',
                        status: 'Rejected',
                    },
                    {
                        id:6,
                        desc: 'Completed Shifts',
                        status: 'Completed',
                    },
            ],
            shifts:{},
            filterStatus : null,
            shift_status : null,
            timeline_shift : {},
            releaseprocessing: false,
        }
    },


    mounted(){
        // this.fetchShiftRequests();   
    },

    watch: {
        // currentTabComponent : {
        //     handler : function(newvalue, oldValue)
        //     {
        //         if(newvalue)
        //         {
        //             this.fetchShiftRequests();
        //         }
        //     }
        // }
        '$route.hash':{
            handler(newHash){
            // removing character '#'
            var newStr = newHash.replace('#','')
            this.currentTabComponent = newStr ? newStr : 'Pending'
            this.helper();
            },
            immediate: true, 
            deep: true,
        },
    },
    methods:{
        helper(){
            if(this.currentTabComponent == 'ApplyForLeave'){
                this.filterStatus = 'Apply For Leave'
            }else{ this.filterStatus = this.currentTabComponent}
            this.fetchShiftRequests();

        },
       fetchShiftRequests(url) {
        url = url ?? '/shift/employee/index';
        this.$axios.get(url,{params: {status: this.filterStatus}})
        .then(response => {
            let data = response.data.payload;
            this.shifts=data;              
        }).catch(error => {
            this.handleErrorResponse(error.response, "error");
        }).finally(function () {
        });
    },
    rejectRelease({status , timeline}){
        this.shift_status = status;
        this.timeline_shift = timeline;
        this.timeline_shift.reject_processing = true;
        this.submitActions()
    },

    acceptRelease({status,timeline}){
        this.shift_status = status;
        this.timeline_shift = timeline;
        this.checkCounts();
    },

    confirmOverTimeShift() {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to accept overtime shifts, the user has already scheduled 40 hours in a week.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
        if (result.value) {
            this.timeline_shift.accept_processing = true;
            this.submitActions();
        }
      });
    },

    checkCounts()
    {
      this.$axios
      .get("counts-stats/index", {
        params: {employee_id : this.timeline_shift.shift.employee_id , shift_id:  this.timeline_shift.shift.shift_id }
      })
      .then((response) => {
        let totalHours = response.data.payload;
        if(totalHours > 40 && this.currentTabComponent == 'Pending')
        {
          this.confirmOverTimeShift();
        }else{
            this.timeline_shift.accept_processing = true;
            this.submitActions();
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });
    },

    submitActions(){
        if(this.currentTabComponent=="ApplyForLeave" ){
            if(this.shift_status=="Rejected"){
                this.shift_status="Leave Rejected";
            }else{
                this.shift_status="Released"
            }
        }
        let self =this;
        self.$axios.post('shift/employee/shift-action/'+ this.timeline_shift.shift.id, {
            status:this.shift_status ,
            employee_id:this.timeline_shift.shift.employee_id,
            area_role_id:this.timeline_shift.shift.area_role_id,
            shift_id:this.timeline_shift.shift.shift_id,
            slot_id:this.timeline_shift.shift.slot_id,
            action_detail:this.timeline_shift.shift.action_detail
        })
        .then(response => {
            let data = response.data;
            this.shifts.data = this.shifts.data.filter((shift) => shift.id != this.timeline_shift.shift.id);
            self.triggerSwal(data.message, "success");
        })
        .catch(error => {
            self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
            this.timeline_shift.accept_processing = false;
            this.timeline_shift.reject_processing = false;
            this.shift_status = null,
            this.timeline_shift = {}
        });
    },

    releaseShift({status, releaseDocument, releaseReason, select_release_reason, shiftTimeline}) {
      let self = this;
      if (self.releaseprocessing) {
        return;
      }
      self.releaseprocessing = true;
      if (select_release_reason != "other") {
        releaseReason = select_release_reason;
      }

      let fd = new FormData();
      fd.append("release_docs", releaseDocument);
      fd.append("status", status);
      fd.append("area_role_id", shiftTimeline.area_role_id);
      fd.append("shift_id", shiftTimeline.shift_id);
      fd.append("slot_id", shiftTimeline.slot_id);
      fd.append("employee_id", shiftTimeline.employee.id);
      fd.append("action_detail", releaseReason);

      self.$axios
        .post("shift/employee/shift-action/" + shiftTimeline.id, fd )
        .then((response) => {
          let data = response.data;
          this.shifts = this.shifts.data.filter((obj) => obj.id != shiftTimeline.id);
          self.triggerSwal(data.message, "success");
          this.fetchShiftRequests();
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.releaseprocessing = false;
          this.$refs.releaseTable.select_release_reason = null;
          this.$refs.releaseTable.release_reason = null;
          this.$refs.releaseTable.release_document = null;
          this.$refs.releaseTable.releaseShiftModel = false;
          this.$refs.releaseTable.$v.$reset();
        });
    },

  }
}
</script>
<template>
    <Layout>
        <PageHeader :items="items" />
        <div class="row">
                <div class="col-12 col-md mb-3">
            <ul class="nav nav-pills " role="tablist">
                <li class="nav-item" v-for="(tab,index) in tabs" :key="index">
                    <a class="nav-link d-flex align-items-center" :title="tab.desc"
                       :class="{gradientColor : currentTabComponent === tab.status,
                        'text-white': currentTabComponent === tab.status,}"
                       :id="tab.id"
                       @click="currentTabComponent = tab.status"
                       data-toggle="tab" :href="'#' + currentTabComponent" aria-controls="loan-file" role="tab"
                       aria-selected="true">
                        <i class="me-md-2 me-0 fas" :class="tab.icon"></i><span class="d-sm-block">{{ tab.desc }}</span>
                    </a>
                </li>
            </ul>
        </div>
        </div>
        <div class="card">
            <div class="card-body under-tabs">
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content">
                    <!-- <component :shifts="shifts" @shiftAction="submitActions($event)" :is="currentTabComponent"></component> -->
                            <Table :shifts="shifts" ref="releaseTable" :release-processing="releaseprocessing" :status="currentTabComponent" @rejectRelease="rejectRelease($event)" @acceptRelease="acceptRelease($event)" @paginatedData="fetchShiftRequests($event)" @releaseShift='releaseShift($event)'></Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>